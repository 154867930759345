import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Multimedia/Audio/SuccessBox';
import AudioTable from 'components/Web_User_Interface/1080p_Series/Multimedia/Audio/audioTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Multimedia Menu // Audio Settings",
  "path": "/Web_User_Interface/1080p_Series/Multimedia/Audio/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Here you can adjust the amplification of the cameras microphones/audio-in as well as the volume of the audio-out signal.",
  "image": "./WebUI_1080p_SearchThumb_Multimedia_Audio.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Multimedia_Audio.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Multimedia Menu // Audio Settings' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Here you can adjust the amplification of the cameras microphones/audio-in as well as the volume of the audio-out signal.' image='/images/Search/WebUI_1080p_SearchThumb_Multimedia_Audio.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Multimedia_Audio.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Multimedia/Audio/' locationFR='/fr/Web_User_Interface/1080p_Series/Multimedia/Audio/' crumbLabel="Audio Settings" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "multimedia-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#multimedia-menu",
        "aria-label": "multimedia menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multimedia Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5a186cb2c8f153a5b69266e7b9bd1694/bb27a/1080p_Settings_Multimedia_Audio.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69.1304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACt0lEQVQoz2MoKystLy+rqKioqqouKy0qbp9TPOd0wfTDmZMPQVDGpEO5Uw9VLzhV1jypqry8uqomKjJKU0vHycmZoaS0vLi0vKKioqGhQV9Xu7ax7dPf/88//Hr99f/rr/9ffAaRV59833Tx66LpU2oT4gsjYhK9ggJMLbytbBjKyitKykCaGxsbZOWVq6pq//////P71////oDQ/7/////9/vvv0x//986fNCM/rzG+tM4/Is/ePNTWiaGioqK4tLysvKK2rt7WwnDR3On/////8+fPfzB4+fX/3Td/f957+P/p83mdk8sCEidnpfVmljlYhjvbuzOUlEGdXVvXYGmgXlNWcPP23Qc3rzy5f+fuzevbD1xYvevc6eWrLq9e256fc6Ah8lSl+bmJ6UvLcov8HUB+hji7rq5OW0efAQzUxXnLY3xNFBSSnXSTXAxlFZWU1TQKYj3zw2xDDNT7QmQOpohvyLRiKIYFWF1dna6uLgMDAzsbKwMDS7q1yMICi0211nF2GkqystFBgTGJcfIKmmpKGiIikgZS/DXuWgwQD8M06zEwMDAxsbAwMQcYCeX5KfcEa4VaWdhqyjTW13u5uqgqKKqoqAoLCghwsvkZKYJCG6wXpFlLS4uBgYGDmdFKilFJhImBgbHQVOx0kGCkhohDeLiGuZGKhpKYjAQbBxsHI4OfgTRDJRiAAqy21sHejk9QLN1FwddCnldU2l5TvUNNPV9FxUJH2yLQOSQ3KiAtxNjFwsDEQFFV2cTcDKoZAurr65ubmxobGmvrG5ubm1obGpsaG8saG+saGhpqQURbW2tzEwiCQGMjiua6OpCK+oaGxoYGCKO+saEZzG1sBInV19U3IAGGqqpKCIJrRgP1YIBVBJRIQAgUW5VV1TWVVdUgqqa2pqYGl1m1tbXV1dVVVVUAkZRzqS+YKWIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a186cb2c8f153a5b69266e7b9bd1694/e4706/1080p_Settings_Multimedia_Audio.avif 230w", "/en/static/5a186cb2c8f153a5b69266e7b9bd1694/d1af7/1080p_Settings_Multimedia_Audio.avif 460w", "/en/static/5a186cb2c8f153a5b69266e7b9bd1694/7f308/1080p_Settings_Multimedia_Audio.avif 920w", "/en/static/5a186cb2c8f153a5b69266e7b9bd1694/b0250/1080p_Settings_Multimedia_Audio.avif 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5a186cb2c8f153a5b69266e7b9bd1694/a0b58/1080p_Settings_Multimedia_Audio.webp 230w", "/en/static/5a186cb2c8f153a5b69266e7b9bd1694/bc10c/1080p_Settings_Multimedia_Audio.webp 460w", "/en/static/5a186cb2c8f153a5b69266e7b9bd1694/966d8/1080p_Settings_Multimedia_Audio.webp 920w", "/en/static/5a186cb2c8f153a5b69266e7b9bd1694/f16bf/1080p_Settings_Multimedia_Audio.webp 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a186cb2c8f153a5b69266e7b9bd1694/81c8e/1080p_Settings_Multimedia_Audio.png 230w", "/en/static/5a186cb2c8f153a5b69266e7b9bd1694/08a84/1080p_Settings_Multimedia_Audio.png 460w", "/en/static/5a186cb2c8f153a5b69266e7b9bd1694/c0255/1080p_Settings_Multimedia_Audio.png 920w", "/en/static/5a186cb2c8f153a5b69266e7b9bd1694/bb27a/1080p_Settings_Multimedia_Audio.png 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5a186cb2c8f153a5b69266e7b9bd1694/c0255/1080p_Settings_Multimedia_Audio.png",
              "alt": "Web User Interface - 1080p Series - Multimedia Audio",
              "title": "Web User Interface - 1080p Series - Multimedia Audio",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <AudioTable mdxType="AudioTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      